<template>
  <div class="background-container">
    <Snackbar :SnackbarComponent="SnackbarComponent" />
    <div class="gradient-background"></div>
    <div class="shape shape-left"></div>
    <div class="shape shape-right"></div>
    <div class="shape shape-bottom"></div>
    <v-row :style="{ height: `${vuetifyBreakpoints.height}px` }" align="center" no-gutters class="overflow-auto">
      <v-col cols="12" align="center" class="px-5">
        <v-card max-height="500" max-width="500" rounded="xl" class="py-12 px-12">
          <div class="text-left">
            <v-img max-width="150" src="@/assets/CallCentral_logo.png" />
          </div>
          <v-divider class="my-3" />
          <div class="text-left FontSize20px font-weight-bold">SignIn</div>
          <v-form ref="LoginForm" class="mt-4">
            <v-text-field
              variant="solo"
              flat
              bg-color="#f2f2f2"
              class="TextFieldVariant1 text-left"
              placeholder="Enter Email ID"
              v-model="SignIn.email_id"
              :rules="[(v) => !!v || 'Required', (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email address']"
              @keypress.enter.prevent="ValidateMethod('SEND_OTP')"
            ></v-text-field>
            <div class="FontSize12px text-left" v-if="is_otp_field_enabled">We've sent a One-Time Password (OTP) to your registered email address. Please check your inbox to proceed.</div>
            <v-otp-input v-show="is_otp_field_enabled" ref="otpInput" :length="6" class="OTPField" v-model="SignIn.one_time_password" @keypress.enter.prevent="ValidateMethod('VERIFY_OTP')"></v-otp-input>
          </v-form>
          <div class="text-left">
            <v-btn v-if="is_otp_field_enabled && counter === 0" size="small" variant="text" color="primary" :disabled="counter !== 0" class="text-capitalize pa-0 ma-0" @click="ValidateMethod('SEND_OTP')"
              >Resend OTP</v-btn
            >
            <span v-if="is_otp_field_enabled == true && counter !== 0" class="text-greenColorVariant1 font-weight-bold FontSize12px">Resend OTP available in {{ counter }}</span>
          </div>
          <v-card-actions class="pa-0 ma-0">
            <v-btn color="primary" variant="flat" :loading="LoginLoader" class="custom-transform-class text-none" @click="ValidateMethod(is_otp_field_enabled ? 'VERIFY_OTP' : 'SEND_OTP')">{{
              is_otp_field_enabled ? "Verify OTP" : "Get OTP"
            }}</v-btn>
          </v-card-actions>
          <div class="mt-12 FontSize12px font-weight-bold">
            Don't have an account? Start your journey with us, <span class="text-decoration-underline text-primary CursorPointerVariant1" @click="$router.push('SignUpPage')">Signup</span>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { signIn, confirmSignIn, signOut } from "aws-amplify/auth";
import Snackbar from "@/components/Extras/Snackbar.vue";
import { useDisplay } from "vuetify";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    is_otp_field_enabled: false,
    LoginLoader: false,

    counter: 0,
    interval: null,

    SnackbarComponent: {},
    vuetifyBreakpoints: {},
    SignIn: {
      email_id: "",
      one_time_password: "",
    },
  }),
  watch: {
    "SignIn.one_time_password"() {
      this.MoveCursorToLastColumnMethod();
    },
    "SignIn.email_id"(val) {
      if (val && val !== null) {
        this.SignIn.email_id = val.toLowerCase();
      }
    },
  },
  mounted() {
    this.vuetifyBreakpoints = useDisplay();
    this.disableBrowserRoutingChanges();
  },
  methods: {
    MoveCursorToLastColumnMethod() {
      const otpInput = this.$refs.otpInput.$el || this.$refs.otpInput;

      const inputs = otpInput.querySelectorAll("input");
      if (inputs.length > 0) {
        inputs[inputs.length - 2].focus();
      }
    },
    async ValidateMethod(action) {
      const { valid } = await this.$refs.LoginForm.validate();
      if (valid) {
        this.SignInMethod(action);
      } else {
        this.SnackbarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "redColorVariant3",
          SnackbarText: `Please enter email to login`,
        };
      }
    },
    async SignInMethod(action) {
      switch (action) {
        case "SEND_OTP":
          try {
            this.LoginLoader = true;
            this.user = await signIn({
              username: this.SignIn.email_id,
              options: {
                authFlowType: "CUSTOM_WITHOUT_SRP",
              },
            });
            console.log("User", this.user.nextStep.signInStep);
            if (this.user.nextStep.signInStep === "CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE") {
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "primary",
                SnackbarText: `Verification OTP sent to ${this.SignIn.email_id}`,
              };
              this.counter = 120;
              this.LoginLoader = false;
              this.is_otp_field_enabled = true;
              setTimeout(() => {}, 1000);
              this.$forceUpdate();
              this.startCounter();
            }
          } catch (error) {
            if (error.message === "DefineAuthChallenge failed with error NOT_AUTHORIZED : Kindly Sigup.") {
              this.LoginLoader = false;
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "redColorVariant3",
                SnackbarText: 'User does not exit. Please Sign-up',
              };
            } else if (error.message === "There is already a signed in user.") {
              await signOut({ global: true });
              this.SignInMethod("SEND_OTP");
            } else {
              this.LoginLoader = false;
              this.SnackbarComponent = {
                SnackbarVmodel: true,
                SnackbarColor: "redColorVariant3",
                SnackbarText: error.message,
              };
            }

            console.log("Err", error);
          }
          break;
        case "VERIFY_OTP":
          try {
            this.LoginLoader = true;
            await confirmSignIn({ challengeResponse: this.SignIn.one_time_password })
              .then(() => {
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "primary",
                  SnackbarText: "Login successful",
                };
                this.LoginLoader = false;
                this.ActivateMethod();
              })
              .catch((err) => {
                if (err.message === "VerifyAuthChallengeResponse failed with error Incorrect OTP!!." || err.message === "Invalid session for the user.") {
                  this.counter = 0;
                  this.Login.otp = "";
                }
                this.SnackbarComponent = {
                  SnackbarVmodel: true,
                  SnackbarColor: "redColorVariant3",
                  SnackbarText: err.message,
                };
                this.OTPErrorMessage = err.message;
                this.LoginLoader = false;
              });
          } catch (error) {
            console.log("err", error);
          }
          break;
      }
    },
    disableBrowserRoutingChanges() {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
    },
    startCounter() {
      this.interval = setInterval(() => {
        if (this.counter > 0) {
          this.counter--;
        } else {
          clearInterval(this.interval);
        }
      }, 1000);
    },
    beforeDestroy() {
      clearInterval(this.interval);
    },
    ActivateMethod() {
      sessionStorage.setItem("user_email_id", this.SignIn.email_id);
      setTimeout(() => {
        this.$router.push("LandingPage");
      }, 1500);
    },
  },
};
</script>

<style scoped>
.background-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* padding: 30px !important; */
}

.gradient-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh !important;
  padding-bottom: 18px !important;
  background: linear-gradient(145deg, #005536, #e0b208, #4cc498);
  background-size: 200% 200%;
  animation: gradient-animation 10s ease infinite;
}

.shape {
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  filter: blur(0px);
  border-radius: 10px;
}

.shape-left {
  top: 10%;
  left: -5%;
  width: 150px;
  height: 300px;
  transform: rotate(30deg);
}

.shape-right {
  top: 20%;
  right: -5%;
  width: 200px;
  height: 400px;
  transform: rotate(-30deg);
}

.shape-bottom {
  bottom: -10%;
  left: 30%;
  width: 400px;
  height: 150px;
  transform: rotate(15deg);
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
